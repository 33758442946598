import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d32746ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "large-content setup-page" }
const _hoisted_2 = { class: "ion-padding" }
const _hoisted_3 = { class: "module-cards" }
const _hoisted_4 = { class: "checkbox-wrapper" }
const _hoisted_5 = { class: "description-text-wrapper" }
const _hoisted_6 = { class: "description-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_create_header = _resolveComponent("create-header")!
  const _component_create_progress_bar = _resolveComponent("create-progress-bar")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_error_message = _resolveComponent("error-message")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_create_header, {
        title: `${_ctx.lang.t('title')} (${_ctx.CreateService.countSelectedModules()})`,
        onBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/create/title')))
      }, null, 8, ["title"]),
      _createVNode(_component_create_progress_bar, { step: 1 }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.lang.t("description")), 1),
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (mod) => {
                  return (_openBlock(), _createBlock(_component_ion_card, {
                    key: mod.key,
                    onClick: ($event: any) => (_ctx.toggle(mod)),
                    id: `module_${mod.key}`,
                    class: "module-card"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_header, null, {
                        default: _withCtx(() => [
                          (mod.interactive)
                            ? (_openBlock(), _createBlock(_component_ion_card_subtitle, { key: 0 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.lang.t("interactive")), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_ion_card_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(mod.lang.t("title")), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_ion_card_content, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_col, { size: "auto" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_4, [
                                    _createVNode(_component_ion_checkbox, {
                                      checked: _ctx.CreateService.info.modules[mod.key],
                                      class: "checkbox"
                                    }, null, 8, ["checked"])
                                  ])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_5, [
                                    _createElementVNode("div", _hoisted_6, _toDisplayString(mod.lang.t("desc")), 1)
                                  ])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ion_col, { size: "auto" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    icon: mod.icon,
                                    class: "title-icon",
                                    color: "success"
                                  }, null, 8, ["icon"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["onClick", "id"]))
                }), 128))
              ]),
              _createVNode(_component_ion_row, { class: "ion-justify-content-end ion-padding" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, { size: "auto" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        disabled: !_ctx.validation.valid,
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.submit())),
                        id: "continue_modules"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.langGeneric.t("continue")) + " ", 1),
                          _createVNode(_component_ion_icon, { icon: _ctx.arrowForward }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["disabled"]),
                      _createVNode(_component_error_message, {
                        value: _ctx.lang.t(_ctx.validation.message)
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}