
import { computed, defineComponent, onBeforeMount } from "vue";

import {
  chatbubbleOutline,
  calendarOutline,
  thumbsUpOutline,
  documentTextOutline,
  mapOutline,
  arrowForward,
  listOutline,
} from "ionicons/icons";

import {
  IonPage,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardSubtitle,
  IonCheckbox,
  IonRow,
  IonCol,
  IonCardTitle,
  IonIcon,
  IonButton,
} from "@ionic/vue";

import CreateHeader from "./CreateHeader.vue";
import ErrorMessage from "../generic/form/ErrorMessage.vue";
import CreateProgressBar from "./CreateProgressBar.vue";

import LanguageService, { Translator } from "../../lib/service/LanguageService";
import CreateService from "../../lib/service/CreateService";
import { Module } from "../../../../shared/protocol/Create";
import { ValidationResult } from "../../lib/form/Validation";
import { useRouter } from "vue-router";

interface ModuleListEntry {
  key: Module;
  active: boolean;
  icon: string;
  lang: Translator;
  interactive: boolean;
}

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonCard,
    CreateHeader,
    IonCardSubtitle,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonCheckbox,
    IonRow,
    IonCol,
    IonIcon,
    IonButton,
    ErrorMessage,
    CreateProgressBar,
  },
  watch: {
    $route(to) {
      if (to.path === "/create/modules") {
        CreateService.resetConfigState();
      }
    },
  },
  setup() {
    const lang = LanguageService.getTranslator("create/modules");
    const langGeneric = LanguageService.getTranslator("generic");
    const list: ModuleListEntry[] = [
      {
        key: "date",
        icon: calendarOutline,
        lang: LanguageService.getTranslator("module/date"),
        interactive: true,
        active: false,
      },
      {
        key: "checklist",
        icon: listOutline,
        lang: LanguageService.getTranslator("module/checklist"),
        interactive: true,
        active: false,
      },
      {
        key: "vote",
        icon: thumbsUpOutline,
        lang: LanguageService.getTranslator("module/vote"),
        interactive: true,
        active: false,
      },
      {
        key: "description",
        icon: documentTextOutline,
        lang: LanguageService.getTranslator("module/description"),
        interactive: false,
        active: false,
      },
      {
        key: "discuss",
        icon: chatbubbleOutline,
        lang: LanguageService.getTranslator("module/discuss"),
        interactive: true,
        active: false,
      },
      {
        key: "map",
        icon: mapOutline,
        lang: LanguageService.getTranslator("module/map"),
        interactive: false,
        active: false,
      },
    ];
    const router = useRouter();

    const validation = computed<ValidationResult>(() => {
      if (CreateService.countSelectedModules() <= 0) {
        return {
          valid: false,
          message: "noModuleSelected",
        };
      }
      return {
        valid: true,
      };
    });

    const toggle = (entry: ModuleListEntry) => {
      entry.active = !entry.active;
      CreateService.toggleModuleSelected(entry.key);
    };

    const submit = () => {
      if (validation.value.valid) {
        router.push("/create/setup");
      }
    };

    onBeforeMount(() => {
    if (!CreateService.checkGuard("modules")) {
      router.push("/create");
    }
    });

    return {
      lang,
      langGeneric,
      list,
      CreateService,
      arrowForward,
      toggle,
      submit,
      validation
    };
  }
});
